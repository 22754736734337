import axios from "axios";
// import Swal from "sweetalert2";
// import funciones from "./../../mixins/funciones";
const state = {
  cuentas: [],
  validacionBanco: null,
};
const mutations = {
  SET_CUENTAS(state, data) {
    state.cuentas = data;
  },
  SET_VALIDACION_BANCO(state, data) {
    state.validacionBanco = data;
  },
};
const actions = {
  async getListBanksDetailsCargar({ commit }) {
    let vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "getListBanksDetailsCargar",
      params: {
        id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
      },
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        commit("SET_CUENTAS", response.data.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getValidaNroOp({ commit }, nro_operacion) {
    let vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        "validar_nro_operacion?nro_operacion=" +
        nro_operacion,

      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        commit("SET_VALIDACION_BANCO", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

export default {
  namespace: true,
  state,
  mutations,
  actions,
};
