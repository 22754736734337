<template>
  <v-app id="inspire">
    <v-navigation-drawer
      width="320"
      v-model="$store.state.drawer"
      app
      v-if="mostrarBtnMenu"
    >
      <listMenu />
    </v-navigation-drawer>

    <v-app-bar app elevation="0" :color="colorBar()" dark>
      <v-app-bar-nav-icon
        v-if="mostrarBtnMenu"
        @click="$store.state.drawer = !$store.state.drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $store.state.mainTitle }}</v-toolbar-title>
      <!-- CONTROL DE GASTOS -->
      <v-spacer></v-spacer>
      <!-- <span
        class="white--text mr-5"
        v-if="
          getNameUrl() != 'newQuote' &&
          getNameUrl() != 'verQuote' &&
          getNameUrl() != 'editQuote' &&
          getNameUrl() != 'editQuote' &&
          !this.$route.params.id_house
        "
      >
        ¡Atención! te quedan 15 días de prueba gratis.
      </span>
      <v-btn
        v-if="
          getNameUrl() != 'newQuote' &&
          getNameUrl() != 'verQuote' &&
          getNameUrl() != 'editQuote' &&
          getNameUrl() != 'editQuote' &&
          !this.$route.params.id_house
        "
        color="#527AC7"
        dark
        small
        >
        Adquiere ahora
        </v-btn
      > -->
      <b v-if="this.$route.params.id_house" class="mr-10 text-h4">
        PROFIT PR:
        {{
          (
            (this.$store.state.totalTotalIngresos
              ? this.$store.state.totalTotalIngresos
              : 0) -
            (this.$store.state.totalTotalEgresos
              ? this.$store.state.totalTotalEgresos
              : 0)
          ).toFixed(2)
        }}
        USD
      </b>

      <b v-if="this.$route.params.id_house" class="mr-10 text-h4">
        / OP:
        {{
          (
            (this.$store.state.totalTotalIngresosOp
              ? this.$store.state.totalTotalIngresosOp
              : 0) -
            (this.$store.state.totalTotalEgresosOp
              ? this.$store.state.totalTotalEgresosOp
              : 0)
          ).toFixed(2)
        }}
        USD
      </b>
      <!-- QUOTE -->
      <v-spacer></v-spacer>
      <!-- nuevo -->
      <label class="monto" v-if="getNameUrl() == 'newQuote'">
        {{
          currencyFormat(
            $store.state.pricing.totalVenta - $store.state.pricing.totalCosto
          )
        }}
      </label>
      <v-spacer
        v-if="getNameUrl() == 'newQuote' && !!$store.state.pricing.btnRegistrar"
      ></v-spacer>

      <v-btn
        color="#009688"
        dark
        v-if="getNameUrl() == 'newQuote' && !!$store.state.pricing.btnRegistrar"
        @click="guardarCotizacion()"
        :disabled="$store.state.pricing.bloquearBtnImprimir"
      >
        GUARDAR
      </v-btn>
      <!-- editar -->
      <label class="monto" v-if="getNameUrl() == 'editQuote'">
        {{
          currencyFormat(
            $store.state.pricing.totalVenta - $store.state.pricing.totalCosto
          )
        }}
      </label>
      <v-spacer v-if="getNameUrl() == 'editQuote'"></v-spacer>

      <v-btn
        color="#009688"
        dark
        v-if="
          getNameUrl() == 'editQuote' &&
          $store.state.pricing.mostrarBtnActualizarFlag
        "
        @click="editarCotizacion()"
      >
        GUARDAR CAMBIOS
      </v-btn>
      <!-- ver -->
      <label class="monto" v-if="getNameUrl() == 'verQuote'">
        {{
          currencyFormat(
            $store.state.pricing.totalVenta - $store.state.pricing.totalCosto
          )
        }}
      </label>
      <v-spacer v-if="getNameUrl() == 'verQuote'"></v-spacer>

      <v-btn
        color="#009688"
        dark
        v-if="
          getNameUrl() == 'verQuote' &&
          $store.state.pricing.mostrarBtnActualizarFlag
        "
        @click="ira('editQuote', $route.params.id)"
      >
        IR A EDITAR
      </v-btn>
      <v-btn
        v-if="getNameUrl() == 'verQuote'"
        class="mx-1"
        color="light-blue darken-3"
        dark
        @click="copiarCotizacion"
      >
        <v-icon class="mx-1" dense small>mdi-content-copy</v-icon>
        COPIAR COTIZACIÓN
      </v-btn>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
    <!-- <v-footer dark padless class="mt-auto">
      <v-card class="flex" flat tile>
        <v-card-title class="teal">
          <strong class="subheading"
            >Get connected with us on social networks!</strong
          >

          <v-spacer></v-spacer>

          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>PIC CARGO</strong>
        </v-card-text>
      </v-card>
    </v-footer> -->
  </v-app>
</template>

<script>
import leftMenu from "@/components/leftMenu";
import listMenu from "@/components/listMenu";
import { Store, mapActions } from "vuex";
import mixins from "@/components/mixins/funciones";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  mixins: [mixins],
  data() {
    return {
      mostrarBtnMenu: true,
    };
  },
  components: {
    leftMenu,
    listMenu,
  },
  beforeMount() {
    this.mostrarBtnMenu = !JSON.parse(localStorage.getItem("dataBranch"))[0]
      .esnuevoflag;
    this.$store.state.drawer = this.mostrarBtnMenu;
  },
  methods: {
    ...mapActions([
      "registrarQuote",
      "crearCarpetaOneDrive",
      "actualizarURLEnElQuote",
      "updateQuote",
      "validarCosto",
      "GuardaCostosFinalesQuote",
      "generaInstructivo",
    ]),
    async guardarCotizacion() {
      let validacion = this.validarRegistro();
      if (!!validacion) {
        this.$store.state.spiner = true;
        await this.registrarQuote({ fullflag: true }).catch((err) => {
          console.log("registrarQuote", err);
        });

        this.$store.state.spiner = false;
        let vm = this;
        Swal.fire({
          icon: "info",
          title: "Aviso",
          text: vm.$store.state.pricing.mensaje,
          confirmButtonText: "Ir a ver",
        }).then((result) => {
          if (result.isConfirmed) {
            vm.$router.push({
              name: "verQuote",
              params: { id: vm.$store.state.pricing.id },
            });
          }
        });
        await this.crearCarpetaOneDrive({
          nro_quote: this.$store.state.pricing.nro_quote,
          nombre: this.$store.state.pricing.datosPrincipales.nombre,
        }).catch((err) => {
          console.log("crearCarpetaOneDrive", err);
        });

        await this.actualizarURLEnElQuote({
          id: this.$store.state.pricing.id,
          url: this.$store.state.pricing.urlFolder,
        }).catch((err) => {
          console.log("actualizarURLEnElQuote", err);
        });
      }
    },
    async editarCotizacion() {
      // let val = await this.validarCosto();
      let val = true;
      val = !this.$store.state.pricing.opcionCostos.some(
        (v) =>
          !v.date_end || !v.tiempo_transito || !this.isDateValid(v.date_end)
      );
      let vm = this;

      // // -----------------------------------------------------
      if (val) {
        vm.$store.state.spiner = false;
        await vm.updateQuote();
        await vm.generaInstructivo();
        // await vm.GuardaCostosFinalesQuote();
        vm.$router.push({
          name: "verQuote",
          id: vm.$store.state.pricing.id,
        });
        vm.$store.state.spiner = false;
        // } else {
        //   this.$store.state.pricing.costoflag =
        //     !this.$store.state.pricing.costoflag;
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error en la cotización",
        //     text: `${textError} Verifique.`,
        //   });
      } else {
        Swal.fire({
          title: "ADVERTENCIA",
          icon: "error",
          text: "Existe una o más Fechas de validez y/o tiempos en tránsito, INCOMPLETOS. Verifique.",
        });
        this.$store.state.pricing.tab = 2;
      }
    },
    copiarCotizacion() {
      Swal.fire({
        title: "ADVERTENCIA",
        text: "¿Desea Crear una copia de esta cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.state.spiner = true;
          await this.registrarQuote({ fullflag: true }).catch((err) => {
            console.log("registrarQuote", err);
          });

          this.$store.state.spiner = false;
          let vm = this;

          Swal.fire({
            icon: "info",
            title: "Aviso",
            text: vm.$store.state.pricing.mensaje,
            confirmButtonText: "Ir a ver",
          }).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push({
                name: "verQuote",
                params: { id: vm.$store.state.pricing.id },
              });
              location.reload();
            }
          });
          await this.crearCarpetaOneDrive({
            nro_quote: this.$store.state.pricing.nro_quote,
            nombre: this.$store.state.pricing.datosPrincipales.nombre,
          }).catch((err) => {
            console.log("crearCarpetaOneDrive", err);
          });

          await this.actualizarURLEnElQuote({
            id: this.$store.state.pricing.id,
            url: this.$store.state.pricing.urlFolder,
          }).catch((err) => {
            console.log("actualizarURLEnElQuote", err);
          });
        }
      });
    },
    isDateValid(date) {
      if (!date) {
        return true; // Pasa la validación si el campo está vacío
      }

      const selectedDate = moment(date, "YYYY-MM-DD", true);
      const today = moment();

      return selectedDate.isAfter(today);
    },
    validarRegistro() {
      let val = true;

      if (
        this.$store.state.pricing.opcionCostos.some((v) => v.date_end == "")
      ) {
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "La fecha de validez para las opciones es requerida.",
        });
        val = false;
      } else {
        if (
          !this.$store.state.pricing.opcionCostos.some((v) =>
            this.isDateValid(v.date_end)
          )
        ) {
          Swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "La fecha de validez  para las opciones debe ser mayor que la fecha actual.",
          });
          val = false;
        }
      }
      if (
        !this.$store.state.pricing.opcionCostos.some((v) => v.tiempo_transito)
      ) {
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "El tiempo de transito  para las opciones es requerido. ",
        });
        val = false;
      }
      if (val) {
        return val;
      }

      let validacioncosto = null;
      this.$store.state.pricing.opcionCostos.forEach((element) => {
        let validateCostos = this.validateCost({
          costo: element.listCostos.filter((v) => v.esopcionflag == 1),
          multiplicador: this.$store.state.pricing.listMultiplicador,
          services: this.$store.state.pricing.listServices,
        });
        if (validateCostos.estado) {
          validacioncosto = validateCostos;
          val = false;
        }
      });
      if (val) {
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: validacioncosto.mensaje,
        });
        val = validacioncosto.estado;
      }
      return val;
    },
    getNameUrl() {
      let name = this.$route.name;

      return name;
    },
    obtenerTipoAlert() {
      let type = "red";
      if (
        this.$store.state.pricing.totalCosto -
          this.$store.state.pricing.totalVenta <
        0
      ) {
        type = "success";
      }
      if (
        this.$store.state.pricing.totalCosto -
          this.$store.state.pricing.totalVenta >
        0
      ) {
        type = "error";
      }
      return type;
    },
    currencyFormat(number) {
      let money = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
      }).format(number);
      return money;
    },
    colorBar() {
      let color = "#252C32";
      if (this.getNameUrl() == "newQuote") {
        color = this.obtenerTipoAlert();
      }
      if (this.getNameUrl() == "editQuote") {
        color = this.obtenerTipoAlert();
      }
      if (this.getNameUrl() == "verQuote") {
        color = this.obtenerTipoAlert();
      }
      return color;
    },
    ira(ruta, id) {
      this.$router.push({
        name: ruta,
        params: {
          id: id,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    isDateValid(date) {
      if (!date) {
        return true; // Pasa la validación si el campo está vacío
      }
      const selectedDate = moment(date, "YYYY-MM-DD", true);
      const today = moment();
      return selectedDate.isAfter(today);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 10px !important;
  background: #f4f7fa;
}
.perdida {
  background: red !important;
  color: white !important;
}
.ganancia {
  background: green !important;
  color: white !important;
}
.v-stepper v-stepper--is-booted v-stepper--non-linear v-sheet theme--light {
  color: white !important;
}
.monto {
  font-size: 2rem;
}
</style>
