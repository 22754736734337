// validToken
import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";
import StatePrincipal from "./../index";
const state = {
  response: {},
  errorClave: "",
  frmLogin: {
    user: "",
    password: "",
    id_sucursal: "",
  },
  MostrarLogin: true,
  MostrarSucursal: false,
  lstSucursal: [],
};
const mutations = {
  SET_RESPONSE(state, data) {
    state.response = data;
  },
  SET_SUCURSAL_X_USUARIO(state, data) {
    state.lstSucursal = data;
  },
};
const actions = {
  async ValidarToken({ commit, dispatch }) {
    const ruta = router.currentRoute.name;
    let menu = JSON.parse(localStorage.getItem("menu"));
    let dataBranch = JSON.parse(localStorage.getItem("dataBranch"))[0];
    var headers = {
      "auth-token": localStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "validToken",
      headers: headers,
    };

    await axios(config).then(async (response) => {
      let res = response.data;
      if (!!res.estadoflag && ruta == "Login" && !!menu) {
        await dispatch("_getMenuItem");
        if (dataBranch.esnuevoflag) {
          router.push({ name: "ConfiguracionInicial" }, () => {});
        } else {
          router.push({ name: "Main" }, () => {});
        }
        // router.push({ name: "Main" });
      }
      commit("SET_RESPONSE", res);
    });
    return state.response;
  },
  async singin({ commit, dispatch }) {
    state.errorClave = "";
    var headers = {
      "auth-token": localStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "singin",
      headers: headers,
      data: state.frmLogin,
    };

    await axios(config).then(async (response) => {
      let res = response.data;

      if (!!res.estadoflag) {
        localStorage.setItem("security", response.data.data[0].id_secutiry);
        localStorage.setItem("auth-token", response.data.token);
        let dataUSer = response.data.data;

        dataUSer[0].id_user = response.data.data[0].id;
        dataUSer[0].id = response.data.data[0].id_entitie;

        localStorage.setItem("dataUser", JSON.stringify(dataUSer));
        await dispatch("CargarBranch");
        if (state.lstSucursal.length == 1) {
          let dataBranch = state.lstSucursal;
          localStorage.setItem("dataBranch", JSON.stringify(dataBranch));

          // --------------------------------------------
          let datos = JSON.parse(localStorage.getItem("dataUser"));
          datos[0].id_branch = dataBranch[0].id;
          localStorage.setItem("dataUser", JSON.stringify(datos));
          localStorage.setItem("iso_pais", dataBranch[0].iso_pais);
          localStorage.setItem("dataBranch", JSON.stringify(dataBranch));
          await setTimeout(async () => {
            await dispatch("_getMenuItem");
            StatePrincipal.state.lstMenu = JSON.parse(
              localStorage.getItem("menu")
            );
          }, 1000);
          // await this.ListProgrammedPayment();

          if (dataBranch[0].esnuevoflag) {
            router.push({ name: "ConfiguracionInicial" }, () => {});
          } else {
            router.push({ name: "Main" }, () => {});
          }
        } else {
          state.MostrarLogin = false;
          state.MostrarSucursal = true;
        }
      } else {
        state.errorClave = res.mensaje;
      }
    });
    return state.response;
  },
  async CargarBranch({ commit }) {
    // response.data.data[0].id
    let id_usuario = JSON.parse(localStorage.getItem("dataUser"))[0].id_user;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "CargarBranch/" + id_usuario,

      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        var res = response.data;

        commit("SET_SUCURSAL_X_USUARIO", res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  // async ListProgrammedPayment() {
  //   this.errorBranch = "";
  //   let datos = JSON.parse(localStorage.getItem("dataUser"));
  //   let dataBranch = state.lstSucursal.filter(
  //     (v) => v.id == state.frmLogin.id_sucursal
  //   );
  //   datos[0].id_branch = state.frmLogin.id_sucursal;
  //   // localStorage.setItem("dataUser", JSON.stringify(datos));
  //   // localStorage.setItem("iso_pais", dataBranch[0].iso_pais);
  //   // localStorage.setItem("dataBranch", JSON.stringify(dataBranch));
  //   var config = {
  //     method: "get",
  //     url: process.env.VUE_APP_URL_MAIN + "ListProgrammedPayment",

  //     headers: {
  //       "auth-token": localStorage.getItem("auth-token"),
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   await axios(config)
  //     .then(function (response) {
  //       var res = response.data.data;
  //       router.push({
  //         name: "Main",
  //       });
  //       if (res.filter((v) => v.totalpendiente == 1).length > 0) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "ADVERTENCIA",
  //           text: "Existen pagos pendientes por realizar.",
  //           allowEscapeKey: false,
  //           allowEnterKey: false,
  //           allowOutsideClick: false,
  //         }).then((res) => {
  //           // if (res.isConfirmed) {
  //           //   window.location.reload();
  //           // }
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // },
  async _getMenuItem({ commit }) {
    this.state.dataMenu = false;
    var dataUser = JSON.parse(localStorage.getItem("dataUser"));
    var data = JSON.stringify({
      id_entitie: dataUser[0].id_user,
      id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getMenuModules",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        localStorage.setItem("menu", JSON.stringify(response.data.data));
        commit("_setItemsMenu", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
export default {
  namespace: true,
  state,
  mutations,
  actions,
};
