<template>
  <section>
    <div class="contenedor">
      <LoginComponent v-if="$store.state.securitys.MostrarLogin" />
      <SucursalComponent v-if="$store.state.securitys.MostrarSucursal" />
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  components: {
    LoginComponent: () =>
      import("../components/Security/Accesos/LoginComponent.vue"),
    SucursalComponent: () =>
      import("../components/Security/Accesos/SucursalComponent.vue"),
  },
  data() {
    return {};
  },
};
</script>
<style lang="css" scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: url("../../public/img/png/background.png");
  background-position: center;
  background-size: cover;
}

.contenedor {
  position: relative;
  width: 400px;
  backdrop-filter: blur(100px);
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.transicion-activo {
  opacity: 0;
  pointer-events: none; /* Para que los elementos no sean clicables mientras están ocultos */
}
</style>
