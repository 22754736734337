import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import moment from "moment";
import StatePrincipal from "./../index";
const state = {
  dataEntitiePost: [],
  persona: [],
  statusEntitie: false,
  lstProveedor: [],
  lstPersona: [],
  titleVal: "",
  filtro: {
    correlativo: "",
    bussiness_name: "",
    id_document: "",
    id_pais: "",
    id_state: "",
    status: "1",
    id_tipoproveedor: "",
    names: "",
    surname: "",
    second_surname: "",
  },
  proveedor: {
    correlativo: "",
    bussiness_name: "",
    id_role: 0,
    id_tipotransaccion: 56,
    id_tipodocumento: 0,
    id_pais: 0,
    id_ciudad: 0,
    dias_credito: 0,
    dias_sobreestadia: 0,
    dias_almacenaje: 0,
    direccion: "",
    notas: "",
    nro_documento: "",
    nro_documento: "",
    emailaddress: ""
  },
  cliente: {
    correlativo: "",
    names: "",
    surname: "",
    second_surname: "",
    birthday: "",
    id_role: 0,
    id_tipotransaccion: 56,
    id_tipodocumento: 0,
    id_pais: 0,
    id_sex: 0,
    id_ciudad: 0,
    dias_credito: 0,
    dias_sobreestadia: 0,
    dias_almacenaje: 0,
    direccion: "",
    notas: "",
    nro_documento: "",
    emailaddress: ""
  },
  lstTelefono: [],
  lstContactos: [],
  lstInformacionBancaria: [],
  lstConvenios: [],
  lstTarifas: [],
  informacionEntrega: {},
  lstShippers: [],
  id: null,
  filtrarProveedor: false,
};

const getters = {};

const mutations = {
  _setEntitie(state, dataEntitie) {
    if (dataEntitie.status == 200) {
      state.statusEntitie = true;
    } else {
      state.statusEntitie = false;
    }
  },
  SET_PERSONA(state, data) {
    state.persona = data;
  },
  SET_LIST_PROVEEDOR(state, data) {
    state.lstProveedor = data;
  },
  SET_LIST_PERSONA(state, data) {
    state.lstPersona = data;
  },
};

const actions = {
  async CargarPersona({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `cargar_persona?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_PERSONA", data.data);
        } else {
          commit("SET_PERSONA", []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async ListarPersonaTipoPersona({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `listar_persona_tipo_persona?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PERSONA", data.data);
        } else {
          commit("SET_LIST_PERSONA", []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async guardarRolProveedor({ commit, dispatch }) {
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_MAIN + `actualizar_rol_proveedor`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: {
        id: state.lstPersona.map((persona) => {
          return persona.id;
        }),
        proveedorflag: state.lstPersona.map((persona) => {
          return persona.proveedorflag;
        }),
        clienteflag: state.lstPersona.map((persona) => {
          return persona.clienteflag;
        }),
        personalflag: state.lstPersona.map((persona) => {
          return persona.personalflag;
        }),
      },
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        Swal.fire({
          icon: !!data.estadoflag ? "success" : "error",
          title: "Aviso",
          text: data.mensaje,
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getListProveedor({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `listado_proveedor?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }&correlativo=${
          state.filtro.correlativo ? state.filtro.correlativo : ""
        }&bussiness_name=${
          state.filtro.bussiness_name ? state.filtro.bussiness_name : ""
        }&id_document=${
          state.filtro.id_document ? state.filtro.id_document : ""
        }&id_pais=${
          state.filtro.id_pais ? state.filtro.id_pais : ""
        }&id_state=${
          state.filtro.id_state ? state.filtro.id_state : ""
        }&status=${
          state.filtro.status == 1 || state.filtro.status == 0
            ? state.filtro.status
            : ""
        }&id_tipoproveedor=${
          state.filtro.id_tipoproveedor ? state.filtro.id_tipoproveedor : ""
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PROVEEDOR", data.data);
        } else {
          commit("SET_LIST_PROVEEDOR", []);

          Swal.fire({
            icon: data.status == "401" ? "error" : "info",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getValidaRazonSocial({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `validar_razon_social?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }&razonsocial=${
          state.proveedor.bussiness_name ? state.proveedor.bussiness_name : ""
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PROVEEDOR", data.data);
        } else {
          commit("SET_LIST_PROVEEDOR", []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getValidaDocument({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `validar_documento?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }&id_tipodocumento=${state.proveedor.id_tipodocumento}&documento=${
          state.proveedor.nro_documento
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PROVEEDOR", data.data);
        } else {
          commit("SET_LIST_PROVEEDOR", []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getVerProveedor({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `ver_proveedor?id=${router.currentRoute.params.id}`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (!!data.estadoflag) {
          let res = data.data[0];
          state.proveedor               = res;
          state.lstTelefono             = res.lsttelefono.map((item) => {
            return {
              ...item,
              id_tipotelefono: item.id_tipotelefono.toString(),
            };
          });
          state.lstContactos            = res.lstcontactos.map((item) => {
            return {
              ...item,
              id_tipotelefono: item.id_tipotelefono.toString(),
            };
          });
          state.lstInformacionBancaria  = res.lstinformacionbancaria.map(
            (item) => {
              return {
                ...item,
                id_tipotelefono: item.id_intermediario
                  ? item.id_intermediario.toString()
                  : "",
              };
            }
          );
          state.lstConvenios            = res.lstconvenios;
          state.lstTarifas              = res.lsttarifas;
        } else {
          if (data.status == "401") {
            Swal.fire({
              icon: "error",
              text: data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Aviso",
              text: data.mensaje,
              allowOutsideClick: false,
              confirmButtonText: "Ir al listado",
            }).then((res) => {
              if (res.isConfirmed) {
                state.lstProveedor = [];
                router.push({
                  name: "listProveedor",
                });
              }
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async guardarProveedor({ commit }) {
    state.proveedor.id_branch = JSON.parse(
      localStorage.getItem("dataUser")
    )[0].id_branch;
    state.proveedor.lstTelefono = state.lstTelefono;
    state.proveedor.lstContactos = state.lstContactos;
    state.proveedor.lstInformacionBancaria = state.lstInformacionBancaria;
    state.proveedor.lstConvenios = state.lstConvenios;
    state.proveedor.lstTarifas = state.lstTarifas;
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + `registrar_proveedor`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: state.proveedor,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);

        if (data.status == "401") {
          Swal.fire({
            icon: "error",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        } else {
          Swal.fire({
            icon: !!data.estadoflag ? "success" : "error",
            title: "Aviso",
            text: data.mensaje,
            allowOutsideClick: false,
            confirmButtonText: "Ir al listado",
          }).then((res) => {
            if (res.isConfirmed) {
              state.lstProveedor = [];
              router.push({
                name: "listProveedor",
              });
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async actualizarProveedor({ commit }) {
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_MAIN + `actualizar_proveedor`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: {
        id: router.currentRoute.params.id,
        id_role: state.proveedor.id_role,
        id_tipotransaccion: state.proveedor.id_tipotransaccion,
        id_tipodocumento: state.proveedor.id_tipodocumento,
        id_pais: state.proveedor.id_pais,
        id_ciudad: state.proveedor.id_ciudad,
        dias_credito: state.proveedor.dias_credito,
        dias_sobreestadia: state.proveedor.dias_sobreestadia,
        dias_almacenaje: state.proveedor.dias_almacenaje,
        notas: state.proveedor.notas,
        direccion: state.proveedor.direccion,
        emailaddress: state.proveedor.emailaddress,
        lstTelefono: state.lstTelefono,
        lstContactos: state.lstContactos,
        lstInformacionBancaria: state.lstInformacionBancaria,
        lstConvenios: state.lstConvenios,
        lstTarifas: state.lstTarifas,
      },
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);

        if (data.status == "401") {
          Swal.fire({
            icon: "error",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        } else {
          Swal.fire({
            icon: !!data.estadoflag ? "success" : "error",
            title: "Aviso",
            text: data.mensaje,
            allowOutsideClick: false,
            confirmButtonText: "Ir al listado",
          }).then((res) => {
            if (res.isConfirmed) {
              state.lstProveedor = [];
              router.push({
                name: "listProveedor",
              });
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async eliminarPersona({ commit, dispatch }, data) {
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_MAIN + `eliminar_proveedor`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        Swal.fire({
          icon: !!data.estadoflag ? "success" : "error",
          title: "Aviso",
          text: data.mensaje,
          allowOutsideClick: false,
          confirmButtonText: "Aceptar",
        }).then((res) => {
          if (res.isConfirmed) {
            state.lstProveedor = [];
            // dispatch("getListProveedor");
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async telConctactoProveedor({ commit, dispatch }, data) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN + `tel_contacto_proveedor?id=${data.id}`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        let res = data.data[0];
        state.lstTelefono = res.lsttelefono.map((item) => {
          return {
            ...item,
            id_tipotelefono: item.id_tipotelefono
              ? item.id_tipotelefono.toString()
              : "",
          };
        });
        state.lstContactos = res.lstcontactos.map((item) => {
          return {
            ...item,
            id_tipotelefono: item.id_tipotelefono
              ? item.id_tipotelefono.toString()
              : "",
          };
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async exportListProveedor() {
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "aplication/json",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      responseType: "arraybuffer",
    };
    let data = {
      id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
      correlativo: state.filtro.correlativo,
      bussiness_name: state.filtro.bussiness_name,
      id_document: state.filtro.id_document,
      id_pais: state.filtro.id_pais,
      id_state: state.filtro.id_state,
      status: state.filtro.status,
      id_tipoproveedor: state.filtro.id_tipoproveedor,
    };
    Swal.fire({
      icon: "info",
      title: "Aviso",
      text: "El documento se descargará automaticamente",
      showConfirmButton: false,
      showCloseButton: false,
      showDenyButton: false,
      allowOutsideClick: false,
    });
    await axios
      .post(
        process.env.VUE_APP_URL_MAIN + `export_list_proveedor`,
        data,
        headers
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // let name = this.uuidv4();
        link.setAttribute(
          "download",
          `Listo Proveedor ${moment().format("DD-MM-YYYY hh:mm:ss")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    Swal.fire({
      icon: "info",
      title: "Aviso",
      text: "Documento Descargado",
      confirmButtonText: "Ok",
      showConfirmButton: true,
      showCloseButton: false,
      showDenyButton: false,
      allowOutsideClick: false,
    });
  },
  //  CLIENTES

  async getListClientes({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `listado_clientes?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }&correlativo=${
          state.filtro.correlativo ? state.filtro.correlativo : ""
        }&names=${state.filtro.names ? state.filtro.names : ""}&surname=${
          state.filtro.surname ? state.filtro.surname : ""
        }&second_surname=${
          state.filtro.second_surname ? state.filtro.second_surname : ""
        }&id_document=${
          state.filtro.id_document ? state.filtro.id_document : ""
        }&id_pais=${
          state.filtro.id_pais ? state.filtro.id_pais : ""
        }&id_state=${
          state.filtro.id_state ? state.filtro.id_state : ""
        }&status=${
          state.filtro.status == 1 || state.filtro.status == 0
            ? state.filtro.status
            : ""
        }&id_tipoproveedor=${
          state.filtro.id_tipoproveedor ? state.filtro.id_tipoproveedor : ""
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PROVEEDOR", data.data);
        } else {
          commit("SET_LIST_PROVEEDOR", []);

          Swal.fire({
            icon: data.status == "401" ? "error" : "info",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getValidaDocumentCliente({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `validar_documento?id_branch=${
          JSON.parse(localStorage.getItem("dataUser"))[0].id_branch
        }&id_tipodocumento=${state.cliente.id_tipodocumento}&documento=${
          state.cliente.nro_documento
        }`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: this.dataEntitiePost,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (data.estadoflag == true) {
          commit("SET_LIST_PROVEEDOR", data.data);
        } else {
          commit("SET_LIST_PROVEEDOR", []);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async guardarCliente({ commit }) {
    state.cliente.lstTelefono = state.lstTelefono;
    state.cliente.lstContactos = state.lstContactos;
    state.cliente.lstInformacionBancaria = state.lstInformacionBancaria;
    state.cliente.id_branch = JSON.parse(
      localStorage.getItem("dataUser")
    )[0].id_branch;

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + `registrar_cliente`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: {
        id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
        names: state.cliente.names ? state.cliente.names : "",
        surname: state.cliente.surname ? state.cliente.surname : "",
        second_surname: state.cliente.second_surname ? state.cliente.second_surname : "",
        birthday: state.cliente.birthday ? state.cliente.birthday : "",
        id_role: state.cliente.id_role ? state.cliente.id_role : "",
        id_tipotransaccion: state.cliente.id_tipotransaccion ? state.cliente.id_tipotransaccion : "",
        id_tipodocumento: state.cliente.id_tipodocumento ? state.cliente.id_tipodocumento : "",
        id_pais: state.cliente.id_pais ? state.cliente.id_pais : "",
        id_sex: state.cliente.id_sex ? state.cliente.id_sex : "",
        id_ciudad: state.cliente.id_ciudad ? state.cliente.id_ciudad : "",
        dias_credito: state.cliente.dias_credito ? state.cliente.dias_credito : "",
        dias_sobreestadia: state.cliente.dias_sobreestadia ? state.cliente.dias_sobreestadia : "",
        dias_almacenaje: state.cliente.dias_almacenaje ? state.cliente.dias_almacenaje : "",
        direccion: state.cliente.direccion ? state.cliente.direccion : "",
        notas: state.cliente.notas ? state.cliente.notas : "",
        nro_documento: state.cliente.nro_documento ? state.cliente.nro_documento : "",
        emailaddress: state.cliente.emailaddress,
        lstContactos: state.lstContactos,
        lstInformacionBancaria: state.lstInformacionBancaria,
        lstTelefono: state.lstTelefono,
        lstConvenios: state.lstConvenios,
        lstTarifas: state.lstTarifas,
        informacionEntrega: state.informacionEntrega,
        lstShippers: state.lstShippers,
      },
    };

    await axios(config)
      .then(function (response) {
        // console.log(response)
        let data = response.data;
        localStorage.setItem("auth-token", data.token);

        if (data.status == "401") {
          Swal.fire({
            icon: "error",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        } else {
          Swal.fire({
            icon: !!data.estadoflag ? "success" : "error",
            title: "Aviso",
            text: data.mensaje,
            allowOutsideClick: false,
            confirmButtonText:
              StatePrincipal.state.modalEntitie == true
                ? "Aceptar"
                : "Ir al listado",
          }).then((res) => {
            if (res.isConfirmed) {
              if (StatePrincipal.state.modalEntitie == true) {
                StatePrincipal.state.registroClienteFlag =
                  !StatePrincipal.state.registroClienteFlag;
                StatePrincipal.state.entities.id = data.data[0].id_entitie;
                StatePrincipal.state.modalEntitie = false;
              } else {
                state.lstProveedor = [];
                router.push({
                  name: "listClientes",
                });
              }
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async verCliente({ commit }) {
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `ver_cliente?id=${router.currentRoute.params.id}`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        // console.log(response);

        let data = response.data;
        localStorage.setItem("auth-token", data.token);
        if (!!data.estadoflag) {
          let res = data.data[0];
          
          state.cliente                 = res;
          state.lstTelefono             = res.lsttelefono.map((item) => {
            return {
              ...item,
              id_tipotelefono: item.id_tipotelefono.toString(),
            };
          });
          state.lstContactos            = res.lstcontactos.map((item) => {
            return {
              ...item,
              id_tipotelefono: item.id_tipotelefono.toString(),
            };
          });
          state.lstInformacionBancaria  = res.lstinformacionbancaria.map(
            (item) => {
              return {
                ...item,
                id_tipotelefono: item.id_intermediario
                  ? item.id_intermediario.toString()
                  : "",
              };
            }
          );
          state.lstConvenios            = res.lstconvenios;
          state.lstTarifas              = res.lsttarifas;
          state.informacionEntrega      = res.informacionentrega[0] || { status: true }
          state.lstShippers             = res.lstshippers;
        } else {
          if (response.data.status == "401") {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Aviso",
              text: data.mensaje,
              allowOutsideClick: false,
              confirmButtonText: "Ir al listado",
            }).then((res) => {
              if (res.isConfirmed) {
                state.lstProveedor = [];
                router.push({
                  name: "listProveedor",
                });
              }
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async actualizarCliente({ commit }) {
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_MAIN + `actualizar_cliente`,
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: {
        id: router.currentRoute.params.id,
        names: state.cliente.names ? state.cliente.names : "",
        surname: state.cliente.surname ? state.cliente.surname : "",
        second_surname: state.cliente.second_surname ? state.cliente.second_surname : "",
        birthday: state.cliente.birthday ? state.cliente.birthday : "",
        id_role: state.cliente.id_role ? state.cliente.id_role : "",
        id_tipotransaccion: state.cliente.id_tipotransaccion ? state.cliente.id_tipotransaccion : "",
        id_tipodocumento: state.cliente.id_tipodocumento ? state.cliente.id_tipodocumento : "",
        id_pais: state.cliente.id_pais ? state.cliente.id_pais : "",
        id_sex: state.cliente.id_sex ? state.cliente.id_sex : "",
        id_ciudad: state.cliente.id_ciudad ? state.cliente.id_ciudad : "",
        dias_credito: state.cliente.dias_credito ? state.cliente.dias_credito : "",
        dias_sobreestadia: state.cliente.dias_sobreestadia ? state.cliente.dias_sobreestadia : "",
        dias_almacenaje: state.cliente.dias_almacenaje ? state.cliente.dias_almacenaje : "",
        direccion: state.cliente.direccion ? state.cliente.direccion : "",
        notas: state.cliente.notas ? state.cliente.notas : "",
        nro_documento: state.cliente.nro_documento ? state.cliente.nro_documento : "",
        emailaddress: state.cliente.emailaddress,
        lstContactos: state.lstContactos,
        lstInformacionBancaria: state.lstInformacionBancaria,
        lstTelefono: state.lstTelefono,
        lstConvenios: state.lstConvenios,
        lstTarifas: state.lstTarifas,
        informacionEntrega: state.informacionEntrega,
        lstShippers: state.lstShippers
      },
    };

    await axios(config)
      .then(function (response) {
        let data = response.data;
        localStorage.setItem("auth-token", data.token);

        if (data.status == "401") {
          Swal.fire({
            icon: "error",
            text: data.mensaje,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((resSwal) => {
            if (resSwal.isConfirmed && data.status == "401") {
              router.push({ name: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 10);
            }
          });
        } else {
          Swal.fire({
            icon: !!data.estadoflag ? "success" : "error",
            title: "Aviso",
            text: data.mensaje,
            allowOutsideClick: false,
            confirmButtonText: "Ir al listado",
          }).then((res) => {
            if (res.isConfirmed) {
              state.lstProveedor = [];
              router.push({
                name: "listClientes",
              });
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async exportListClientes() {
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "aplication/json",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      responseType: "arraybuffer",
    };
    let data = {
      id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
      correlativo: state.filtro.correlativo ? state.filtro.correlativo : "",
      names: state.filtro.names ? state.filtro.names : "",
      surname: state.filtro.surname ? state.filtro.surname : "",
      second_surname: state.filtro.second_surname
        ? state.filtro.second_surname
        : "",
      id_document: state.filtro.id_document ? state.filtro.id_document : "",
      id_pais: state.filtro.id_pais ? state.filtro.id_pais : "",
      id_state: state.filtro.id_state ? state.filtro.id_state : "",
      status:
        state.filtro.status == 1 || state.filtro.status == 0
          ? state.filtro.status
          : "",
      id_tipoproveedor: state.filtro.id_tipoproveedor
        ? state.filtro.id_tipoproveedor
        : "",
    };
    Swal.fire({
      icon: "info",
      title: "Aviso",
      text: "El documento se descargará automaticamente",
      showConfirmButton: false,
      showCloseButton: false,
      showDenyButton: false,
      allowOutsideClick: false,
    });
    await axios
      .post(process.env.VUE_APP_URL_MAIN + `export_list_cliente`, data, headers)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // let name = this.uuidv4();
        link.setAttribute(
          "download",
          `Listo Proveedor ${moment().format("DD-MM-YYYY hh:mm:ss")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    Swal.fire({
      icon: "info",
      title: "Aviso",
      text: "Documento Descargado",
      confirmButtonText: "Ok",
      showConfirmButton: true,
      showCloseButton: false,
      showDenyButton: false,
      allowOutsideClick: false,
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
